<template>
  <div>
    <div class="row gutter-b">
      <div class="col-md-12">
        <div class="card card-custom">
          <div class="card-header card-header-fixed border-0 px-6">
            <div class="row align-items-center col-8 px-0">
              <div class="col-4 pr-0">
                <div class="input-icon">
                  <input
                    type="text"
                    class="form-control form-control-solid"
                    :placeholder="$t('mealsystem.placeholder.search')"
                    v-model="search"
                  />
                  <span>
                    <i class="flaticon2-search-1 text-muted"></i>
                  </span>
                </div>
              </div>
              <div class="col-4">
                <vs-select
                  v-if="groups"
                  v-model="searchGroup"
                  :options="groups"
                  :placeholder="$t('mealsystem.placeholder.search_groups')"
                  label="name"
                  :reduce="(groups) => groups.raw_material_group_id"
                />
              </div>

              <b-button
                variant="secondary"
                class="mr-3 ml-3 pr-2"
                @click.prevent="removeFilters"
              >
                <i class="fa fa-times icon-md"></i>
              </b-button>
              <b-button variant="primary" @click.prevent="searchItems">
                {{ $t("button.filter") }}
              </b-button>
            </div>
            <div class="card-toolbar">
              <router-link
                  :to="{ name: 'raw_materials_groups' }"
                  class="btn btn-outline-primary mr-3"
                >
                  {{ $t("mealsystem.rawmaterials.button.groups") }}
              </router-link>
              <b-button
                variant="success"
                v-b-modal.modal-item-edit
                @click="setNewItem()"
              >
                <span class="svg-icon svg-icon-md svg-icon-white">
                  <inline-svg src="/media/svg/icons/Navigation/Plus.svg" />
                </span>
                {{ $t("mealsystem.rawmaterials.button.create") }}
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--begin::Card-->
    <div class="card card-custom">
      <!--begin::Body-->
      <div class="card-body">
        <div class="table-responsive">
          <table
            class="table table-head-custom table-head-bg table-vertical-center"
          >
            <thead>
              <tr class="text-left text-uppercase">
                <th width="120">
                  {{ $t("mealsystem.rawmaterials.label.item_number") }}
                </th>
                <th>{{ $t("mealsystem.rawmaterials.label.name") }}</th>
                <th>{{ $t("mealsystem.rawmaterials.label.category") }}</th>
                <th width="300">
                  {{ $t("mealsystem.rawmaterials.label.stock") }}
                </th>
                <th width="90"></th>
              </tr>
            </thead>
            <tbody v-if="!loading">
              <tr v-for="(item, index) in items.data" :key="index">
                <td>
                  <span>{{ item.raw_material_number }} </span>
                </td>

                <td>
                  <span class="font-weight-bolder">{{ item.name }}</span>
                </td>

                <td>
                  <span v-if="item.group != null">{{ item.group.name }}</span>
                  <span v-else class="text-muted">-</span>
                </td>

                <td class="text-success font-weight-bolder">
                  <b-badge v-if="item.amount > 0" variant="success" class="mr-3">{{item.amount}}</b-badge>
                  <b-badge v-else variant="danger" class="mr-3">{{item.amount}}</b-badge>
                  <a
                    class="
                      btn
                      btn-link
                      btn-sm
                      btn
                      btn-clean
                      btn-hover-light-primary
                      btn-icon
                    "
                    v-b-tooltip.left="
                      $t('mealsystem.rawmaterials.title.stock_log')
                    "
                    v-b-modal.modal-item-stocklog
                    @click="getItemDetail(item)"
                    ><i class="fas fa-search icon-md text-primary"></i
                  ></a>
                  <a
                    class="
                      btn
                      btn-link
                      btn-sm
                      btn
                      btn-clean
                      btn-hover-light-primary
                      btn-sm
                    "
                    v-b-modal.modal-fill-stock
                    @click="getItemDetail(item)"
                    ><i class="far fa-plus-square icon-l text-primary"></i>
                    {{ $t("mealsystem.rawmaterials.button.fill_stock") }}</a
                  >
                </td>

                <td>
                  <a
                    class="
                      btn
                      btn-link
                      btn-sm
                      btn
                      btn-clean
                      btn-hover-light-danger
                      btn-sm
                      btn-icon
                    "
                    v-b-tooltip.left="
                      $t('mealsystem.rawmaterials.title.delete_item')
                    "
                    v-b-modal.modal-item-delete
                    @click="setItem(item)"
                    ><i class="ki ki-bold-close icon-sm text-danger"></i
                  ></a>
                  <a
                    class="
                      btn
                      btn-link
                      btn-sm
                      btn
                      btn-clean
                      btn-hover-light-primary
                      btn-sm
                      btn-icon
                    "
                    v-b-tooltip.left="
                      $t('mealsystem.rawmaterials.title.edit_item')
                    "
                    v-b-modal.modal-item-edit
                    @click="getItemDetail(item)"
                    ><i class="ki ki-gear icon-md"></i
                  ></a>
                </td>
              </tr>
            </tbody>
          </table>
          <Pagination :data="items.meta" v-if="!loading"></Pagination>
        </div>
      </div>
      <!--end::Body-->
    </div>
    <!--end::Card-->

    <b-modal
      id="modal-item-delete"
      :okTitle="$t('button.delete')"
      okVariant="danger"
      :title="$t('mealsystem.rawmaterials.title.confirm_delete_item_title')"
    >
      <span
        v-html="$t('mealsystem.rawmaterials.title.confirm_delete_item_text')"
      ></span>
      <template #modal-footer="{ cancel }">
        <b-button variant="secondary" @click="cancel()" class="mr-3">
          {{ $t("button.cancel") }}
        </b-button>
        <b-button variant="danger" @click="deleteItem(selectedItem)">
          {{ $t("mealsystem.rawmaterials.button.delete_item") }}
        </b-button>
      </template>
    </b-modal>

    <b-modal
      id="modal-fill-stock"
      :okTitle="$t('button.save')"
      okVariant="success"
      size="lg"
      :title= "this.selectedItem.name + ' - ' + $t('mealsystem.rawmaterials.title.fill_stock')"
    >
      <ValidationObserver ref="form">
        <div class="row">
          <div class="col-6">
            <ValidationProvider
            name="amout"
            rules="required|min:1"
            v-slot="{ errors }"
          >
            <span class="text-muted mb-2 d-block">{{
              $t("mealsystem.rawmaterials.label.amount")
            }}</span>
            <input
              type="text"
              class="form-control form-control-lg form-control-solid"
              :placeholder="$t('mealsystem.rawmaterials.placeholder.amount')"
              v-model="newStockFill.amount"
            />
            <span class="text-danger">{{ errors[0] }}</span>
          </ValidationProvider>
          <ValidationProvider
            name="price"
            rules="required|min:1"
            v-slot="{ errors }"
          >
            <span class="text-muted mt-5 mb-2 d-block">{{
              $t("mealsystem.rawmaterials.label.price")
            }}</span>
            <input
              type="text"
              class="form-control form-control-lg form-control-solid"
              :placeholder="$t('mealsystem.rawmaterials.placeholder.price')"
              v-model="newStockFill.price"
            />
            <span class="text-danger">{{ errors[0] }}</span>
          </ValidationProvider>
          </div>
          <div class="col-6">
              // TODO - DPH + SupplierID + Invoce Number
          </div>
        </div>
      </ValidationObserver>
        
      <template #modal-footer="{ cancel }">
        <b-button variant="secondary" @click="cancel()" class="mr-3">
          {{ $t("button.cancel") }}
        </b-button>
        <b-button variant="success" @click="fillStock(selectedItem)">
          {{ $t("mealsystem.rawmaterials.button.fill_stock") }}
        </b-button>
      </template>
    </b-modal>

    <b-modal
      id="modal-item-edit"
      :okTitle="$t('button.save')"
      okVariant="success"
      :title="$t('mealsystem.rawmaterials.title.edit_item')"
    >
      <ValidationObserver ref="form">
        
        <ValidationProvider name="name" rules="required|min:2" v-slot="{ errors }">
            <span class="text-muted mb-2 d-block">{{ $t('mealsystem.rawmaterials.label.name') }}</span>  
            <input 
                  type="text" 
                  class="form-control form-control-lg form-control-solid"
                  :placeholder="$t('mealsystem.rawmaterials.placeholder.name')"
                  v-model="selectedItem.name"
            >                
            <span class="text-danger">{{ errors[0] }}</span>
        </ValidationProvider>

        <ValidationProvider name="raw_material_number" rules="required|min:2" v-slot="{ errors }">
            <span class="text-muted mt-5 mb-2 d-block">{{ $t('mealsystem.rawmaterials.label.item_number') }}</span>  
            <input 
                  type="text" 
                  class="form-control form-control-lg form-control-solid"
                  :placeholder="$t('mealsystem.rawmaterials.placeholder.item_number')"
                  v-model="selectedItem.raw_material_number"
            >                
            <span class="text-danger">{{ errors[0] }}</span>
        </ValidationProvider>

        <ValidationProvider name="group_options" v-slot="{ errors }">
            <span class="text-muted mt-5 mb-2 d-block">{{ $t('mealsystem.rawmaterials.label.category') }}</span>  
              <vs-select
                v-if="groups"
                v-model="selectedItem.raw_material_group_id"
                :options="groups"
                :placeholder="$t('mealsystem.rawmaterials.placeholder.group')"
                label="name"
                :reduce="(groups) => groups.raw_material_group_id"
              />
            <span class="text-danger">{{ errors[0] }}</span>
        </ValidationProvider>

        <ValidationProvider name="min_use" rules="required" v-slot="{ errors }">
            <span class="text-muted mt-5 mb-2 d-block">{{ $t('mealsystem.rawmaterials.label.min_use') }}</span>  
            <input 
                  type="text" 
                  class="form-control form-control-lg form-control-solid"
                  :placeholder="$t('mealsystem.rawmaterials.placeholder.min_use')"
                  v-model="selectedItem.min_use"
            >                
            <span class="text-danger">{{ errors[0] }}</span>
        </ValidationProvider>
      </ValidationObserver>

      <template #modal-footer="{ cancel }">
        <b-button variant="secondary" @click="cancel()" class="mr-3">
          {{ $t("button.cancel") }}
        </b-button>
        <b-button variant="success" @click="updateItem(selectedItem)">
          {{ $t("mealsystem.rawmaterials.button.save_item") }}
        </b-button>
      </template>
    </b-modal>

    <b-modal
      id="modal-item-stocklog"
      :okTitle="$t('button.save')"
      okVariant="success"
      size="lg"
      :title="$t('mealsystem.rawmaterials.title.stock_log')"
    >
      <table class="table">
        <thead>
          <tr>
            <th width="120">{{ $t("mealsystem.rawmaterials.label.amount") }}</th>
            <th>{{ $t("mealsystem.rawmaterials.label.price") }}</th>
            <th>{{ $t("mealsystem.rawmaterials.label.filled_at") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(stock, index) in selectedItem.stocks" :key="index">
            <td class="align-middle font-weight-bolder">
              {{ stock.amount }}
            </td>
            <td>{{ stock.price }},-</td>
            <td>
              {{ stock.created_at | moment("D. M. YYYY HH:MM") }}
            </td>
          </tr>
        </tbody>
      </table>

      <template #modal-footer="{ cancel }">
        <b-button variant="secondary" @click="cancel()" class="mr-3">
          {{ $t("button.close") }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import Pagination from "@/view/content/Pagination";
import { checkErrors } from "@/core/utils/api-errors";

export default {
  data() {
    return {
      items: null,
      groups: null,
      searchGroup: null,
      loading: false,
      page: 1,
      search: "",
      selectedItem: {},
      newStockFill: {},
    };
  },
  components: {
    Pagination,
  },
  beforeMount() {
    this.getItems();
    this.getGroups();
  },
  metaInfo() {
    return { title: this.$t("mealsystem.meta.raw_materials_list") };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: this.$t("mealsystem.meta.raw_materials"),
        subtitle: this.$t("mealsystem.meta.raw_materials_list"),
      },
    ]);
  },
  methods: {
    getItems() {
      this.loading = true;
      ApiService.setHeader();
      ApiService.apiGet(
        "/meal-system/raw-materials?page=" +
          this.page +
          "&perpage=" +
          this.perpage +
          "&search=" +
          this.search +
          "&groups=" +
          this.searchGroup
      ).then((response) => {
        this.items = response.data;
        this.loading = false;
      });
    },
    getGroups() {
      this.loading = true;
      ApiService.setHeader();
      ApiService.apiGet("/meal-system/raw-material-groups").then((response) => {
        this.groups = response.data.data;
        this.loading = false;
      });
    },
    setNewItem() {
      this.selectedItem = {};
    },
    setItem(item) {
      this.selectedItem = item;
    },
    getItemDetail(item) {
      ApiService.setHeader();
      ApiService.apiGet("/meal-system/raw-materials/" + item.raw_material_id).then(
        (response) => {
          this.selectedItem = response.data.data;
        }
      );
    },
    deleteItem(item) {
      this.loading = true;
      ApiService.setHeader();
      ApiService.apiPost("/meal-system/raw-materials/" + item.raw_material_id + "/delete")
        .then(() => {
          this.loading = false;
          this.$bvModal.hide("modal-item-delete");
          this.getItems();
        })
        .catch((error) => {
          checkErrors(error);
        });
    },
    fillStock(item) {
      this.loading = true;
      ApiService.setHeader();
      ApiService.apiPost("/meal-system/raw-materials/" + item.raw_material_id + "/stocks", {
        amount: this.newStockFill.amount,
        price: this.newStockFill.price,
        supplier_id: this.newStockFill.supplier_id,
        invoice: this.newStockFill.invoice,
        vat: this.newStockFill.vat,
      })
        .then(() => {
          this.loading = false;
          this.$bvModal.hide("modal-fill-stock");
          this.getItems();
        })
        .catch((error) => {
          checkErrors(error);
        });
    },
    removeFilters() {
      this.page = 1;
      this.search = "";
      this.searchGroup = null;
      this.getItems();
    },
    searchItems() {
      this.page = 1;
      this.getItems();
      this.itemSearch = {
        search: this.search,
      };
    },
    updateItem(item) {
      this.loading = true;
      this.$refs.form.validate().then(success => {
        if (!success) {
          this.loading = false;
          this.$toasted.error("Zkontrolujte zadané údaje.");
          return;
        } 

        if(item.raw_material_id){
          this.endpoint = "/meal-system/raw-materials/" + item.raw_material_id + "/update"
        } else {
          this.endpoint  = "/meal-system/raw-materials";
        }

        ApiService.setHeader();      
        ApiService.apiPost(this.endpoint,{
          'name': item.name,
          'raw_material_number': item.raw_material_number,
          'min_use': item.min_use,
          'raw_material_group_id': item.raw_material_group_id,
        })
          .then(() => {
              this.loading = false;
              this.$bvModal.hide('modal-item-edit');
              this.getItems();
          }).catch(error => {
              checkErrors(error);
        });
      });
    }
  },
};
</script>